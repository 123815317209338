import React, { useEffect } from "react";
import { render } from "react-dom";
import { padding } from "polished";
import {
  ThemeProvider,
  makeStyles
} from "@fluentui/react";
import useMediaQuery from "beautiful-react-hooks/useMediaQuery";

import useTheme from "./theme/useTheme";
import { TopNav } from "./containers/top-nav";
import reportWebVitals from "./reportWebVitals";
import { App } from "./App";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    transition: "background-color 300ms"
  },
  stack: {
    ...padding(theme.spacing.s2, theme.spacing.s1)
  },
  toggle: {
    marginTop: theme.spacing.s1
  }
}));

const Root: React.FunctionComponent = () => {
  const [darkMode, setDarkMode] = React.useState(
    useMediaQuery("(prefers-color-scheme: dark)")
  );

  const toggleThemePreference = () => setDarkMode(!darkMode);

  const theme = useTheme(darkMode ? "dark" : "light");

  const classes = useStyles();

  useEffect(() => {
    document.title = 'Fleet Tracker';
  }, []);

  return (
    <ThemeProvider className={classes.root} theme={theme}>
      <App darkModeSelected={darkMode} toggleThemePreference={toggleThemePreference} />
    </ThemeProvider>
  );
};

const rootElement = document.getElementById("root");

render(<Root />, rootElement);

reportWebVitals();