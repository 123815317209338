import { Stack, Text, getPropsWithDefaults } from "@fluentui/react";
import React, {PropsWithChildren} from "react";

export interface IAuthRouteProps extends PropsWithChildren {
    title: string;
}

const styles = {
    root: {
        // height: '100vh',
        padding: '1.5rem',
        flex: '1 1 0',
        width: '100%',
        maxWidth: '100%'

    },
};

export const Page: React.FunctionComponent<IAuthRouteProps> = (props) => {
    return (
        <Stack styles={styles}>
            <Text variant="xxLarge">{props.title}</Text>
            {props.children}
        </Stack>
    );
}